import React, { useEffect, useState } from 'react';
import logoImage from '../../assets/images/mundl_logo.jpeg';
import userImage from '../../assets/images/user2.png';
import { fetchWithAuth } from '../../api/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ authToken, userInfos, onSiteValueChange, onIntervalValueChange }) => {

  const [siteValues, setSiteValues] = useState([]);
  const [intervalValues] = useState(['Last 60 Min', 'Today', 'Last 24 Hr', 'Yesterday', 'This Week', 'Last 7 Days']);
  const [selectedSiteValue, setSelectedSiteValue] = useState('');
  const [selectedIntervalValue, setSelectedIntervalValue] = useState(intervalValues[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithAuth('api/v1/sites', authToken);
        setSiteValues(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Could not fetch the data', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000);
    return () => clearInterval(intervalId);

  }, [authToken]);

  useEffect(() => {
    if (siteValues.length > 0) {
      setSelectedSiteValue(siteValues[0].site_id);
    }
  }, [siteValues]);

  useEffect(() => {
    onSiteValueChange(selectedSiteValue);
  }, [selectedSiteValue, onSiteValueChange]);

  useEffect(() => {
    onIntervalValueChange(selectedIntervalValue);
  }, [selectedIntervalValue, onIntervalValueChange]);

  const logout = async () => {
    try {
      console.log(userInfos.email);
      const config = {
        headers: {
          'Authorization': 'Bearer TSZMIENvcnBvcmF0ZSBHcm91cCwgQUcsIFN5c3RlbXMsIA=='
        }
      };

      const response = await axios.post('https://netmain-juniper-api-2scrz.ondigitalocean.app/api/v1/logout/', 
      //const response = await axios.post('http://172.25.64.54:8000/api/v1/logout/', 
      {
        email: userInfos.email,
      }, config);
      if (response.status === 200) {
        localStorage.removeItem('juniper_mist_info');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }


  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{ backgroundColor: '#0000A2' }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="www.mlconsul.com">
          <img src={logoImage} alt="Logo" width="50" height="24" className="d-inline-block align-text-top shadow-lg rounded tw-mr-2" />
          Dashboard Juniper Mist
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item mx-2">
              {isError ? <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3> : null}
              {isLoading ?
                (
                  <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : null}
              {!isError && !isLoading ? (
                siteValues.length > 0 ? (
                  <select
                    className="form-select"
                    value={selectedSiteValue}
                    onChange={(e) => setSelectedSiteValue(e.target.value)}
                  >
                    {siteValues.map((site, index) => (
                      <option key={index} value={site.site_id}>{site.name}</option>
                    ))}
                  </select>
                ) : <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>No sites available</h3>
              ) : null}
            </li>
            <li className="nav-item mx-2">
              {intervalValues.length > 0 ? (
                <select
                  className="form-select"
                  value={selectedIntervalValue}
                  onChange={(e) => setSelectedIntervalValue(e.target.value)}
                >
                  {intervalValues.map((value, index) => (
                    <option key={index} value={value}>{value}</option>
                  ))}
                </select>
              ) : <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>No Data available</h3>}
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item d-flex align-items-center">
              <DateTimeComponent />
              <div className="dropdown ms-3">
                <img src={userImage} alt="User" width="40" height="40" className="rounded-circle dropdown-toggle tw-object-cover" id="userDropdown" data-bs-toggle="dropdown" />
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                  <li><h3 className=' dropdown-item tw-text-lg tw-font-semibold tw-text-gray-800'>{userInfos.fname}</h3></li>
                  <li><button className="dropdown-item tw-text-lg tw-font-semibold tw-text-gray-800" onClick={logout}>Logout</button></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}


const DateTimeComponent = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <span className='text-white tw-text-lg tw-font-bold'>
      {currentDateTime.toLocaleString('en-US', {
        hour12: false,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}
    </span>
  );
};

export default Navbar