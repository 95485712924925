import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { fetchWithAuth } from '../../api/apiService';


const PostConnectionByte = ({ authToken, siteValue, intervalValue }) => {
    const chartRef = useRef(null);
    const [postConnections, setPostConnection] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!siteValue || !intervalValue) {
                return (
                    <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                        <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>please select site name and date</h3>
                    </div>
                );
            }
            try {
                const response = await fetchWithAuth(`api/v1/stats/postconnection?site_id=${siteValue}&timeInterval=${intervalValue}`, authToken);
                setPostConnection({
                    rt: response.data.rt,
                    byte: response.data.byte,
                    byte_convert: response.data.byte_convert,
                });
            } catch (error) {
                console.error('Could not fetch the data', error);
                setIsError(true)
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 600000);
        return () => clearInterval(intervalId);

    }, [authToken, siteValue, intervalValue]);

    useEffect(() => {
        if (!isLoading && chartRef.current) {
            const myChart = echarts.init(chartRef.current, 'dark');

            const option = {
                title: {
                    //text: 'Post Connection Byte',
                    left: 'center',
                    textStyle: {
                        color: '#FFFFFF'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        const param = params[0];
                        const byteConvert = postConnections.byte_convert[param.dataIndex];
                        //return `${param.axisValueLabel}<br/>Byte: ${byteConvert}`;
                        let displayValue;
                        if (byteConvert >= 1073741824) {
                            displayValue = (byteConvert / 1073741824).toFixed(2) + ' GB';
                        } else if (byteConvert >= 1048576) {
                            displayValue = (byteConvert / 1048576).toFixed(2) + ' MB';
                        } else if (byteConvert >= 1024) {
                            displayValue = (byteConvert / 1024).toFixed(2) + ' KB';
                        } else {
                            displayValue = byteConvert + ' bytes';
                        }
                        return `${param.axisValueLabel}<br/>Data: <strong>${displayValue}</strong>`;
                    }
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none',
                            title: {
                                zoom: 'Zoom',
                                back: 'Zoom reset'
                            },
                            iconStyle: {
                                borderColor: '#ffffff',
                                //borderWidth: 1.5  
                            }
                        },
                        restore: {
                            title: 'Restore',
                            iconStyle: {
                                borderColor: '#ffffff',
                                //borderWidth: 1.5    
                            }
                        },
                        saveAsImage: {
                            title: 'Save as Image',
                            iconStyle: {
                                borderColor: '#ffffff',
                                //borderWidth: 1.5    
                            }
                        }
                    }
                },
                dataZoom: [
                    {
                      show: true,
                      realtime: true,
                      start: 0,
                      end: 100,
                      xAxisIndex: [0]
                    },
                    {
                      type: 'inside',
                      realtime: true,
                      start: 0,
                      end: 100,
                      xAxisIndex: [0]
                    }
                  ],
                grid: {
                    left: '25%',
                    right: '10%',
                    top: '10%',
                    bottom: '20%'
                },
                xAxis: {
                    type: 'category',
                    data: postConnections.rt,
                    axisLabel: {
                        margin: 15,
                        color: '#FFFFFF'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        //formatter: '{value} bytes',
                        margin: 30,
                        color: '#FFFFFF',
                        formatter: function (value) {
                            if (value >= 1) {
                                return value.toFixed(1) + ' GB';
                            } else if (value >= 0.001) {
                                return (value * 1024).toFixed(1) + ' MB';
                            } else if (value >= 0.000001) {
                                return (value * 1048576).toFixed(1) + ' KB';
                            } else {
                                return (value * 1073741824).toFixed(0) + ' bytes';
                            }
                        }
                    }
                },
                series: [{
                    //data: postConnections.byte
                    data: postConnections.byte.map(b => b / 1073741824),
                    type: 'line',
                    smooth: true,
                }]
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [postConnections, isLoading]);


    if (isError) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
            </div>
        );
    }
    
    if (isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        );
    }

    return <div className='d-flex justify-content-center align-items-center' ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};

export default PostConnectionByte;
