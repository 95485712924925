import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { fetchWithAuth } from '../../api/apiService';


const TopWlansUsageChartByte = ({ authToken , siteValue, intervalValue}) => {
    const chartRef = useRef(null);
    const [wlans, setWlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!siteValue || !intervalValue) {
                return (
                    <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                        <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>please select site name and date</h3>
                    </div>
                );
            }
            try {
                const response = await fetchWithAuth(`api/v1/usage/top_wlan_usage?site_id=${siteValue}&timeInterval=${intervalValue}`, authToken);
                setWlans(response.data);
            } catch (error) {
                console.error('Could not fetch the data', error);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 600000);
        return () => clearInterval(intervalId);

    }, [authToken, siteValue, intervalValue]);


    useEffect(() => {
        if (!isLoading && chartRef.current) {
            const myChart = echarts.init(chartRef.current, 'dark');
            const transformedData = wlans.map(wlan => ({

                value: wlan.total_byte_sorted,
                name: wlan.ssid,
                totalBytes: wlan.total_bytes
            }));

            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        const name = params.data.name;
                        const totalBytes = params.data.totalBytes;
                        return `${params.marker}${name}<br/>Total Bytes: <strong>${totalBytes}</strong>`;
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    textStyle: {
                        color: '#FFFFFF'
                    }
                },
                toolbox: {
                    feature: {
                        saveAsImage: {
                            iconStyle: {
                                borderColor: '#ffffff'
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Top Wlans Usage per Byte',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            formatter: (params) => `${params.data.name}: ${params.data.totalBytes}`,
                            show: false,
                            position: 'center',
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 25,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: transformedData
                    }
                ]
            };

            option && myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [wlans, isLoading]);

    
    if (isError) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        );
    }

    return <div className='d-flex justify-content-center align-items-center tw-py-1' ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};

export default TopWlansUsageChartByte;
