import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { fetchWithAuth } from '../../api/apiService';

const ChanelUt = ({ authToken, siteValue, intervalValue }) => {

    const chartRefUtBand24 = useRef(null);
    const chartRefChBand24 = useRef(null);
    const chartRefUtBand5 = useRef(null);
    const chartRefChBand5 = useRef(null);
    const [chanelUt, setChanelUt] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAP, setSelectedAP] = useState('');
    const [selectedAPData, setSelectedAPData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!siteValue || !intervalValue) {
                return (
                    <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                        <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>please select site name and date</h3>
                    </div>
                );
            }
            try {
                const response = await fetchWithAuth(`api/v1/clients/channel_ut?site_id=${siteValue}&timeInterval=${intervalValue}`, authToken);
                setChanelUt(response.data);
                if (response.data.length > 0) {
                    setSelectedAP(response.data[0].name);
                }
            } catch (error) {
                console.error('Could not fetch the data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 600000);
        return () => clearInterval(intervalId);

    }, [authToken, siteValue, intervalValue]);

    useEffect(() => {
        const apData = chanelUt.find(ap => ap.name === selectedAP);
        setSelectedAPData(apData);
    }, [selectedAP, chanelUt]);

    // Initialize or update the ECharts instances for band_24 and band_5
    useEffect(() => {
        const initOrUpdateChart = (chartRef, bandData) => {
            if (bandData && chartRef.current) {
                const myChart = echarts.init(chartRef.current);
                const option = {
                    tooltip: {
                        formatter: '{a} <br/>{b} : {c}%'
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {
                                iconStyle: {
                                    borderColor: '#0000A2'
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Utilization',
                            type: 'gauge',
                            progress: {
                                show: true
                            },
                            detail: {
                                valueAnimation: true,
                                formatter: '{value}%'
                            },
                            data: [{ value: bandData.util_all, name: 'Utilization' }]
                        }
                    ]
                };
                myChart.setOption(option);
            }
        };

        if (selectedAPData) {
            initOrUpdateChart(chartRefUtBand24, selectedAPData.bands.band_24);
            initOrUpdateChart(chartRefUtBand5, selectedAPData.bands.band_5);
        }
    }, [selectedAPData]);

    useEffect(() => {
        const initOrUpdateChart = async (chartRef, band, bandData) => {
            if (bandData && chartRef.current) {
                let channelValues = [];
                try {
                    const channelRangeResponse = await fetchWithAuth(`api/v1/clients/ap_channel?band=${band}&bandwidth=${bandData.bandwidth}&site_id=${siteValue}`, authToken);
                    channelValues = channelRangeResponse.data;
                } catch (error) {
                    console.error('Error fetching chart data', error);
                    return;
                }

                const maxValue = Math.max(...channelValues) + 5;
                const minValue = Math.min(...channelValues) - 5;
                const myChart = echarts.init(chartRef.current);
                const option = {
                    tooltip: {
                        formatter: '{a} <br/>{b} : {c}%'
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {
                                iconStyle: {
                                    borderColor: '#0000A2'
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Channel',
                            type: 'gauge',
                            min: minValue,
                            max: maxValue,
                            progress: {
                                show: true
                            },
                            detail: {
                                valueAnimation: true,
                                formatter: '{value}'
                            },
                            data: [{ value: bandData.channel, name: 'Channel' }]
                        }
                    ]
                };
                myChart.setOption(option, true);
            }
        };

        if (selectedAPData) {
            initOrUpdateChart(chartRefChBand24, "band_24", selectedAPData.bands.band_24);
            initOrUpdateChart(chartRefChBand5, "band_5", selectedAPData.bands.band_5);
        }
    }, [selectedAPData, authToken, siteValue]);

    if (isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        );
    }

    if (!selectedAPData) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>No data available for the selected access point.</h3>
            </div>
        );
    }

    return (
        <div className='tw-mt-3 md:tw-h-[450px]'>
            <select className="form-select tw-block tw-w-full tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-700 tw-bg-white tw-bg-clip-padding tw-bg-no-repeat tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-transition tw-ease-in-out tw-m-0 focus:tw-text-gray-700 focus:tw-bg-white focus:tw-border-blue-600 focus:tw-outline-none" aria-label="Wlans"
                value={selectedAP}
                onChange={e => setSelectedAP(e.target.value)}
                style={{ marginBottom: '20px' }}
            >
                {chanelUt.map(ap => (
                    <option key={ap.name} value={ap.name}>{ap.name}</option>
                ))}
            </select>
            <div className='row g-3 justify-content-evenly tw-py-3'>
                <div className='col-md-6 col-sm-12 sm:tw-h-64'>
                    <h3 className='tw-text-center tw-mb-3 tw-text-lg tw-font-semibold tw-text-gray-800'>Utilization Band 2.4 GHz</h3>
                    <div className='tw-shadow-lg tw-bg-white tw-rounded-lg' ref={chartRefUtBand24} style={{ width: '100%', height: '400px' }}></div>
                </div>
                <div className='col-md-6 col-sm-12 sm:tw-h-64 ' >
                    <h3 className='tw-text-center tw-mb-3 tw-text-lg tw-font-semibold tw-text-gray-800'>Utilization Band 5 GHz</h3>
                    <div className='tw-shadow-lg tw-bg-white tw-rounded-lg tw-py-2' ref={chartRefUtBand5} style={{ width: '100%', height: '400px' }}></div>
                </div>
                {/*<div className='col-2'>
                <h3>Channel Band 2.4 GHz</h3>
                <div ref={chartRefChBand24} style={{ width: '400px', height: '400px' }}></div>
            </div>
            <div className='col-2 justify-center'>
                <h3 className='text-align-center'>Channel Band 5 GHz</h3>
                <div ref={chartRefChBand5} style={{ width: '400px', height: '400px' }}></div>
                </div>*/}
            </div>
        </div>
    );
};

export default ChanelUt;
