import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { fetchWithAuth } from '../../api/apiService';


const PostConnectionByteRxTx = ({ authToken, siteValue, intervalValue }) => {
  const chartRef = useRef(null);
  const [postConnections, setPostConnection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!siteValue || !intervalValue) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>please select site name and date</h3>
            </div>
        );
    }
      try {
        const response = await fetchWithAuth(`api/v1/stats/postconnection?site_id=${siteValue}&timeInterval=${intervalValue}`, authToken);
        setPostConnection({
          rt: response.data.rt,
          tx_byte: response.data.tx_byte,
          tx_byte_convert: response.data.tx_byte_convert,
          rx_byte: response.data.rx_byte,
          rx_byte_convert: response.data.rx_byte_convert,
        });
      } catch (error) {
        console.error('Could not fetch the data', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000);
    return () => clearInterval(intervalId);

  }, [authToken, siteValue, intervalValue]);

  useEffect(() => {
    if (!isLoading && chartRef.current) {
      const myChart = echarts.init(chartRef.current, 'dark');
      const seriesColors = {
        'Tx Bytes': '#4679ff',
        'Rx Bytes': '#46e9b6'
      };

      const option = {
        title: {
          //text: 'Tx Byte vs Rx Byte',
          left: 'center',
          textStyle: {
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          /*textStyle: {
            color: '#FFFFFF'
          }*/
          formatter: function (params) {
            let result = `${params[0].axisValue}<br/>`;
            params.forEach((item) => {
              let value = item.value;
              let displayValue;
              if (value >= 1073741824) {
                displayValue = (value / 1073741824).toFixed(2) + ' GB';
              } else if (value >= 1048576) {
                displayValue = (value / 1048576).toFixed(2) + ' MB';
              } else if (value >= 1024) {
                displayValue = (value / 1024).toFixed(2) + ' KB';
              } else {
                displayValue = value + ' bytes';
              }
              result += `${item.marker} ${item.seriesName}: <strong>${displayValue}</strong><br/>`;
            });
            return result;
          }
        },
        legend: {
          data: ['Tx Bytes', 'Rx Bytes'],
          left: 10,
          formatter: function (name) {
            return `${name}`;
          },
          textStyle: {
            color: function (name) {
              return seriesColors[name] || '#FFFFFF'; 
            },
            rich: {
              'Tx Bytes': {
                color: seriesColors['Tx Bytes']
              },
              'Rx Bytes': {
                color: seriesColors['Rx Bytes']
              }
            },
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              iconStyle: {
                borderColor: '#ffffff'
              }
            },
            restore: {
              iconStyle: {
                borderColor: '#ffffff'
              }
            },
            saveAsImage: {
              iconStyle: {
                borderColor: '#ffffff'
              }
            }
          }
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1]
          },
          {
            type: 'inside',
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1]
          }
        ],
        grid: [
          {
            left: 90,
            right: 50,
            height: '30%',
            bottom: '55%'
          },
          {
            left: 90,
            right: 50,
            top: '52%',
            height: '30%'
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: true },
            data: postConnections.rt,
            axisLabel: {
              color: '#FFFFFF'
            },
            show: true
          },
          {
            gridIndex: 1,
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: true },
            data: postConnections.rt,
            position: 'top',
            show: false
          }
        ],
        yAxis: [
          {
            //name: 'Tx Bytes',
            type: 'value',
            //max: 500
            axisLabel: {
              margin: 20,
              color: '#FFFFFF',
              formatter: function (value) {
                if (value >= 1073741824) { // GB
                  return (value / 1073741824).toFixed(1) + ' GB';
                } else if (value >= 1048576) { // MB
                  return (value / 1048576).toFixed(1) + ' MB';
                } else if (value >= 1024) { // KB
                  return (value / 1024).toFixed(1) + ' KB';
                } else { // Bytes
                  return value.toFixed(0) + ' bytes';
                }
              }
            }
          },
          {
            gridIndex: 1,
            //name: 'Rx Bytes',
            type: 'value',
            inverse: true,
            axisLabel: {
              margin: 20,
              color: '#FFFFFF',
              formatter: function (value) {
                if (value >= 1073741824) { // GB
                  return (value / 1073741824).toFixed(1) + ' GB';
                } else if (value >= 1048576) { // MB
                  return (value / 1048576).toFixed(1) + ' MB';
                } else if (value >= 1024) { // KB
                  return (value / 1024).toFixed(1) + ' KB';
                } else { // Bytes
                  return value.toFixed(0) + ' bytes';
                }
              }
            }
          }
        ],
        series: [
          {
            name: 'Tx Bytes',
            type: 'line',
            symbolSize: 8,
            color: seriesColors['Tx Bytes'],
            data: postConnections.tx_byte
          },
          {
            name: 'Rx Bytes',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            symbolSize: 8,
            color: seriesColors['Rx Bytes'],
            data: postConnections.rx_byte
          }
        ]
      };


      myChart.setOption(option);

      return () => {
        myChart.dispose();
      };
    }
  }, [postConnections, isLoading]);

  if (isError) {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
            <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
        </div>
    );
}

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
          <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
              <span className="sr-only"></span>
          </div>
      </div>
  );
  }

  return <div className='d-flex justify-content-center align-items-center' ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};

export default PostConnectionByteRxTx;
