import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../../api/apiService';

const ClientsTableComponent = ({ authToken, siteValue, intervalValue }) => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (!siteValue || !intervalValue) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
                <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>please select site name and date</h3>
            </div>
        );
    }
      try {
        const response = await fetchWithAuth(`api/v1/clients/list?site_id=${siteValue}&timeInterval=${intervalValue}`, authToken);
        setClients(response.data);
      } catch (error) {
        console.error('Could not fetch the data', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000);
    return () => clearInterval(intervalId);

  }, [authToken, siteValue, intervalValue]);


  if (isError) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
        <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  const filteredClients = clients.filter(client => {
    if (filter === 'all') return true;
    return client.band === filter;
  });


  const totalClients = clients.length;
  const clients24GHz = clients.filter(client => client.band === '2.4 GHz').length;
  const clients5GHz = clients.filter(client => client.band === '5 GHz').length;

  return (
    <div>
      <div className='row shadow p-3 bg-body-tertiary rounded mb-3 justify-content-evenly'>
        <div className='col-md-2 col-4 text-center p-2 hover:tw-bg-blue-100 tw-cursor-pointer tw-transition tw-duration-300 tw-ease-in-out' onClick={() => setFilter('all')}>
          <p className='tw-text-xl tw-font-semibold tw-text-gray-700'>({totalClients})</p>
          <p className='tw-text-lg tw-text-gray-600'>All Clients</p>
        </div>
        <div className='col-md-2 col-4 text-center p-2  hover:tw-bg-blue-100 tw-cursor-pointer tw-transition tw-duration-300 tw-ease-in-out' onClick={() => setFilter('2.4 GHz')}>
          <p className='tw-text-xl tw-font-semibold tw-text-gray-700'>({clients24GHz})</p>
          <p className='tw-text-lg tw-text-gray-600'>2.4 GHz Clients</p>
        </div>
        <div className='col-md-2 col-4 text-center p-2  hover:tw-bg-blue-100 tw-cursor-pointer tw-transition tw-duration-300 tw-ease-in-out' onClick={() => setFilter('5 GHz')}>
          <p className='tw-text-xl tw-font-semibold tw-text-gray-700'>({clients5GHz})</p>
          <p className='tw-text-lg tw-text-gray-600'>5 GHz Clients</p>
        </div>
      </div>
      <div className='tw-max-h-[600px] tw-overflow-y-auto'>
        <table className='table table-light caption-top table-sm tw-min-w-full' >
          <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#0000A2', color: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <tr >
              <th className='p-2'>User</th>
              <th className='p-2'>IP Address</th>
              <th className='p-2'>MAC Address</th>
              <th className='p-2'>Device Type</th>
              <th className='p-2'>SSID</th>
              <th className='p-2'>Band</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map((client, index) => (
              <tr key={index} className='hover:tw-bg-blue-300'>
                <td className='p-2'>{client.hostname}</td>
                <td className='p-2'>{client.ip}</td>
                <td className='p-2'>{client.mac}</td>
                <td className='p-2'>{client.device_type}</td>
                <td className='p-2'>{client.ssid}</td>
                <td className='p-2'>{client.band}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientsTableComponent;
