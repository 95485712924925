import axios from 'axios';

const api = axios.create({
  //baseURL: 'http://172.25.64.54:8000/', 
  baseURL: 'https://netmain-juniper-api-2scrz.ondigitalocean.app/', 
  headers: {
    'Content-Type': 'application/json',
  }
});

export const fetchWithAuth = (url, authToken) => {
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
};
